@font-face {
  font-family: "Hogback";
  src: url("Hogback.otf") format("otf"), url("Hogback.woff2") format("woff2");
}

::-webkit-scrollbar {
  width: 11.5px;
}

::-webkit-scrollbar-track {
  background: none;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#cccccc, #cccccc);
  border-radius: 11.5px;
  height: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#a2a2a2, #a2a2a2);
}

body,
html {
  overflow-x: hidden;
  height: 100% !important;
}

a {
  color: #b41e22;
  text-decoration: none;
  box-shadow: 0 -1px #f39a00 inset;
}

a:hover {
  color: #b18944;
}

.card-title {
  font: 5rem;
}

.dialogContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.dialogPrice {
  padding-right: 20px;
}

.dialogButton {
  padding: 20px 0 5px 20px;
}

.cartBottomOptions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cartContainer {
  min-width: auto;
  max-width: 960px;
}

.cart {
  width: 100%;
}

.Cart {
  width: 100%;
}

.divider {
  padding: 50px;
}

.Home {
  max-width: none;
  animation: fadeInAnimation ease 1.75s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-family: "Hogback" !important;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(125px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.homeButton {
  font-family: "Hogback";
  font-size: 3.5rem !important;
  background: rgba(0, 0, 0, 0);
  color: #ffe9c3 !important;
  font-size: 1.75rem;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  /* border: 1px solid #b41e22; */
  box-shadow: 0 -6px #ffe9c3 inset;
  /* border-radius: 2ch; */
}

.homeButton:hover {
  background: #ffe9c3;
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  border: 0px 0px 10px 0px solid #fff;
  text-shadow: none;
}

.homeImage {
  max-width: 100%;
}

.toTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.imageContainer {
  height: 284px;
  width: 100%;
}

.home-alert {
  background-color: #b41e22;
  padding: 2%;
  padding-bottom: 35px;
  margin: 2%;
  margin-bottom: 35px;
  color: #fff;
  border-style: solid;
  border-width: thick;
  border-color: #b18944;
  border-top: #fff;
  border-left: #fff;
}

.home-alert-img {
  height: 125px;
  width: auto;
  margin-bottom: 5%;
}

/* https://media3.giphy.com/media/48PbiL2u5ZUU6HZf55/giphy.gif */
.homeMenuBtn {
  /* The image used */
  /* background-image: url("https://www.englishclub.com/images/vocabulary/food/chinese/chinese-food.jpg"); */
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("https://www.englishclub.com/images/vocabulary/food/chinese/chinese-food.jpg");

  /* Full height */
  height: 275px;
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.homeMenuBtn:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url("https://www.englishclub.com/images/vocabulary/food/chinese/chinese-food.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeLunchBtn:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url("https://www.thespruceeats.com/thmb/X6mg_2VBCQQ2X8VrLcPTf8_4ce0=/2733x2050/smart/filters:no_upscale()/chinese-take-out-472927590-57d31fff3df78c5833464e7b.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeDinnerBtn:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url("https://hips.hearstapps.com/del.h-cdn.co/assets/15/29/1437173075-delish-takeout-healthier.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeLunchBtn {
  /* The image used */
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("https://www.thespruceeats.com/thmb/X6mg_2VBCQQ2X8VrLcPTf8_4ce0=/2733x2050/smart/filters:no_upscale()/chinese-take-out-472927590-57d31fff3df78c5833464e7b.jpg");

  /* Full height */
  height: 275px;
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeDinnerBtn {
  /* The image used */
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("https://hips.hearstapps.com/del.h-cdn.co/assets/15/29/1437173075-delish-takeout-healthier.jpg");

  /* Full height */
  height: 275px;
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 850px) {
  /* for larger screens */
  .homeMenuBtn {
    height: 400px;
  }
  .homeLunchBtn {
    height: 400px;
  }
  .homeDinnerBtn {
    height: 400px;
  }
}

.navText {
  box-shadow: none;
}

.underline {
  text-decoration: underline;
}
